<template>
    <div>
        <VideoHero
            :video-url="videoUrl"
            :video-text="videoText || undefined"
            :pdf-url="pdfUrl"
            :pdf-text="pdfText || undefined"
            :header="header || undefined"
            :video-poster="videoPoster"
            :video-webm-vp9="videoWebmVp9"
            :video-webm-vp8="videoWebmVp8"
            :video-baseline30-mp4="videoBaseline30Mp4"
            :video-ogv="videoOgv"
        />

        <div class="layout__main">
            <FlexibleSection
                v-if="flexibleSection"
                :sections="flexibleSection"
                :spacious="true"
            />
        </div>
    </div>
</template>

<script setup>
import EntryQuery from '~/graphql/queries/AnnualReport.graphql';

defineI18nRoute({
    paths: {
        nl: '/jaarverslagen/[slug]',
        en: '/annual-reports/[slug]'
    }
});

const { variables } = useDefaultVariables();

const response = await useAsyncQuery({
    query: EntryQuery,
    variables
});

const { craftEntry: entry } = useCraftPageEther(response);

const header = computed(() => entry.value?.header ?? null);
const videoUrl = computed(() => entry.value?.videoUrl ?? null);
const videoText = computed(() => entry.value?.videoText ?? null);
const pdfUrl = computed(() => entry.value?.pdfUrl ?? null);
const pdfText = computed(() => entry.value?.pdfText ?? null);
const flexibleSection = computed(() => entry.value?.flexibleSection ?? null);
const videoPoster = computed(() => entry.value?.video?.[0]?.poster?.[0]?.url ?? '');
const videoWebmVp9 = computed(() => entry.value?.video?.[0]?.videoWebmVp9?.[0]?.url ?? '');
const videoWebmVp8 = computed(() => entry.value?.video?.[0]?.videoWebmVp8?.[0]?.url ?? '');
const videoBaseline30Mp4 = computed(() => entry.value?.video?.[0]?.videoBaseline30Mp4?.[0]?.url ?? '');
const videoOgv = computed(() => entry.value?.video?.[0]?.videoOgv?.[0]?.url ?? '');

definePageMeta({
    layout: 'minimal-full-width'
});
</script>
