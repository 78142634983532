<template>
    <div class="video-hero">
        <div class="video-hero__video">
            <video
                v-if="allVideoFormatsProvided"
                ref="videoHero"
                muted
                loop
                autoplay
                playsinline
                webkit-playsinline
                preload="auto"
                width="768"
                height="255"
                class="video-hero__video"
                :poster="videoPoster"
            >
                <source
                    :src="videoWebmVp9"
                    type="video/webm;codecs=vp9"
                >
                <source
                    :src="videoWebmVp8"
                    type="video/webm;codecs=vp8"
                >
                <source
                    :src="videoBaseline30Mp4"
                    type="video/mp4;codecs=avc1.42C01E"
                >
                <source
                    :src="videoOgv"
                    type="video/ogg"
                >
                <source :src="videoBaseline30Mp4">
            </video>
        </div>

        <div class="video-hero__content">
            <h1 class="video-hero__content__title">{{ header }}</h1>
            <button
                v-if="videoUrl"
                class="video-hero__content__button button button--quaternary button--icon-right"
                @click="toggleVideo"
            >
                {{ videoText }}
                <span class="button__icon"><BoomerIcon><IconPlay /></BoomerIcon></span>
            </button>

            <a
                v-if="pdfUrl"
                :href="pdfUrl"
                class="video-hero__content__link button button--quaternary"
            >
                {{ pdfText }}
            </a>

            <a
                href="#video-hero__scroll-to"
                class="video-hero__content__more button button--quaternary"
            >
                <BoomerIcon><IconChevronDown /></BoomerIcon>
            </a>
        </div>

        <ModalPopup
            v-if="showVideo"
            class="modal-popup--full-screen animated fadeIn fast"
            @close="close"
        >
            <template #image>
                <EmbedVideo
                    v-if="videoUrl"
                    :url="videoUrl"
                    :autoplay="true"
                />
            </template>
        </ModalPopup>

        <div
            id="video-hero__scroll-to"
            class="video-hero__scroll-to"
        />
    </div>
</template>

<script setup>
import IconPlay from '~/components/_icons/play.vue';
import IconChevronDown from '~/components/_icons/chevron-down.vue';

const props = defineProps({
    header: {
        type: String,
        required: false,
        default: 'DOEN ondersteunt voorlopers'
    },
    videoUrl: {
        type: String,
        required: false,
        default: ''
    },
    videoText: {
        type: String,
        required: false,
        default: 'Bekijk de video'
    },
    pdfUrl: {
        type: String,
        required: false,
        default: ''
    },
    pdfText: {
        type: String,
        required: false,
        default: 'Of download het volledige jaarverslag'
    },
    videoPoster: {
        type: String,
        required: false,
        default: ''
    },
    videoWebmVp9: {
        type: String,
        required: false,
        default: ''
    },
    videoWebmVp8: {
        type: String,
        required: false,
        default: ''
    },
    videoBaseline30Mp4: {
        type: String,
        required: false,
        default: ''
    },
    videoOgv: {
        type: String,
        required: false,
        default: ''
    }
});

const { videoPoster, videoWebmVp9, videoWebmVp8, videoBaseline30Mp4, videoOgv } = toRefs(props);

const videoHero = ref(null);
const showVideo = ref(false);

const allVideoFormatsProvided = computed(() => videoPoster.value && videoWebmVp9.value && videoWebmVp8.value && videoBaseline30Mp4.value && videoOgv.value);

const playVideo = () => {
    // For super old browsers that do not understand video properties
    if (allVideoFormatsProvided.value && videoHero.value) {
        videoHero.value.muted = true;
        videoHero.value.play();
    }
};

const toggleVideo = () => showVideo.value = !showVideo.value;
const emit = defineEmits(['close']);
const close = () => {
    showVideo.value = false;
    emit('close');
};

onMounted(() => {
    playVideo();
});
</script>

<style lang="less" src="./VideoHero.less" scoped />
